<template>
    <div>
        <div class="my-footer">
            <div class="footer-about-us">
                <!-- <div class="footer-top">
                    <div class="footer-title">让电子签署更简单</div>
                    <div class="footer-options">
                        <el-button type="primary">
                             <a href="https://demo.resrun.cn" target="_blank">在线试用</a>
                        </el-button>
                    </div>
                </div>
                <div class="footer-top-line"></div> -->
                <div class="card-header">
                    <p class="card-title">关于我们 </p>
                    <p class="title-line"></p>
                    <div class="title-desc">
                        <p>北京资源律动科技有限公司成立于2019年，源自于电子合同SaaS公司，专注于提供电子签章相关的专业解决方案，开放签致力于将电子签章产品、技术开源、开放，让电子签署更加简单。我们更加相信秉承开放共享这一价值观，能够为产品与用户之间带来更多信任。</p>
                    
                        <p></p>
                       <!-- <p>后端技术： Springboot、mybatis-plus、shiro、drools、jwt、websocket、freemarker、hutool等。</p>
                        <p>前端技术： Ant-design-vue + Vue + Webpack</p>
                        <p>其他技术： Druid（数据库连接池）、Logback（日志工具）、poi（Excel工具）、PowerJob（定时任务）、lombok（简化代码）</p>
                        <p>项目构建： Maven、Jdk8</p> -->
                    </div>
                </div>
                <div class="about-us">
                    <div class="about-us-left">
                        <div class="concat-us">
                            <ul>
                                <li><span class="concat-title">联系电话：</span>15010993257</li>
                                <!-- <li>QQ群：136-9915-2252</li> -->
                                <li><span class="concat-title">邮箱：</span>service@resrun.cn</li>
                                <li><span class="concat-title">地址：</span> 北京市昌平区珠江摩尔大厦5号楼8F</li>
                            </ul>
                        </div>
                        <div class="web-options">
                            <!-- <el-button>
                                <a href="http://120.53.213.39:8401/#/login?redirect=/login/experience" target="_blank">免费试用</a>
                            </el-button> -->
                            <!-- <el-button>源码下载</el-button> -->
                            <!-- <el-button>立即咨询</el-button> -->
                            <!-- <el-button>更新日志</el-button> -->
                            <!-- <el-button style="margin-top:20px">
                                <a href="https://www.yuque.com/huxin-ch41t/resrun/akk7i0" target="_blank">文档</a>
                            </el-button> -->
                        </div>
                    </div>
                    <div class="web-QR">
                        <div class="QR-item">    
                            <img src="../../assets/images/QQ.png" alt="">
                            <p class="qr-title">QQ群：482074553</p>
                        </div>
                         <div class="QR-item">
                            <img src="../../assets/images/publicwx.jpg" alt="">
                            <p class="qr-title">开放签公众号</p>
                        </div>
                        <div class="QR-item">
                                <img src="../../assets/images/weichat.png" alt="logo">
                                <p class="qr-title">微信咨询</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-footer" id="aboutUs">
            <div class="footer-filing">
                <span>Copyright 2019-2023 北京资源律动科技有限公司 kaifangqian.com 版权所有  </span>
                <a href="https://beian.miit.gov.cn" target="_blank">京ICP备19060018号-4</a>
                <img src="../../assets/images/gongan.png" style="width:18px;margin:0 10px"/>
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011402053714" rel="noreferrer" target="_blank">京公网安备11011402053714</a>
                
            </div>
        </div>
    </div>
   
</template>

<script>
    export default {
        data () {
            return {
            }
        },
        mounted() {
            this.init();
        },
        methods: {

            init(){

            },

        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less">
.my-footer{
    background-color: #222D41;
    width:100%;
    padding-top:80px;
    .card-title{
        font-size: 50px;
        font-family: Alibaba PuHuiTi;
        font-weight: bold;
        color: #fff;
    }
    .title-line{
        width:88px;
        height:3px;
        background:#127fd2;
        margin:0 auto;
        margin-top:14px;
    }
    .title-desc{
        margin-top:25px;
        p{
            font-size: 18px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #8d9198;
            margin:0 auto;
            line-height: 30px;
            opacity: 0.95;
            // margin-top:25px;
            max-width:1200px;
            text-align: left;
            text-indent:2em;
            // width:98%;
        }
    }
 }
.main-footer{
    // background-color: #222D41;
    // width:100%;
    // padding-top:0.8rem;
    
    .footer-filing{

        position: absolute;
        width: 100%;
        // bottom: 0;
        flex-shrink: 0;
        height: 70px;
        text-align: center;
        background-color: #3a4b63;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content:center;
        span{
            font-size: 12px;
            font-family: Alibaba PuHuiTi;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 30px;
            // opacity: 0.38;
            margin:0 10px;
            padding: 0;
        }
        a{
            color:#fff;
            font-size: 12px;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
    }




    
}
.footer-about-us{
        width:1200px;
        margin:0 auto;
    }
    .footer-top{
        // width:1200px;
        // margin:0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .footer-title{
            font-size: 50px;
            font-family: Alibaba PuHuiTi;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 51px
        }
        .footer-options{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .footer-top-line{
        // margin:0 auto;
        margin:70px auto 62px;
        width: 1200px;
        border:1px dashed #888;
    }
    .about-us{
        margin-top:50px;
        display: flex;
        justify-content: space-between;
        .about-us-left{
            display: flex;
        }
        .concat-us{
            .concat-title{
                width:70px;
                display: inline-block;
                // text-align: right;
            }
            li{
                color: #fff;
                margin-bottom: 22px;
                text-align: left;
                font-size: 14px;
                font-family: Alibaba PuHuiTi;
                font-weight: 400;
                color: #FFFFFF;
            
            }
        }
        .web-options{
            margin-left: 40px;
            a{
                text-decoration: none;
                color:#fff;
            }
            .el-button{
                display: block;
                width: 114px;
                padding:7px 20px;
                line-height: 20px;
                background: #222D41;
                border-color: #fff;
                color:#fff;
                margin:0;
                // margin-bottom:110px;
            }
        }
        .web-QR{
            margin-left:46px;
            display: flex;
            .QR-item{
                margin-left:46px;
                img{
                    display: block;
                    width:150px;
                    height: 150px;
                }
              
                .qr-title{
                    font-size: 14px;
                    font-family: Alibaba PuHuiTi;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top:26px;
                    line-height: 20px;
                    opacity: 0.24;
                    margin-bottom:20px;
                }
            }

        }
    }
    

 
</style>;