import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

export const basicRoute  = [
    {
        path: '/',
        name: 'home',
        component: Layout,
        meta: { title: '首页' },
        redirect: '/',
        children: [
            {
                path: '/',
                name: 'home',
                component: () => import(/* webpackChunkName: "user" */ '@/views/home/Home.vue')
            },
            {
                path: '/productFun',
                name: 'productFun', 
                component: () => import(/* webpackChunkName: "user" */ '@/views/home/Home.vue')
            },
            {
                path: '/version',
                name: 'Version',
                component: () => import(/* webpackChunkName: "user" */ '@/views/Version.vue')
            },
            {
                path: 'verification',
                name: 'Verification',
                component: () => import(/* webpackChunkName: "user" */ '@/views/Verification.vue')
            },
            {
                path: 'dynamic',
                name: 'Dynamic',
                component: () => import(/* webpackChunkName: "user" */ '@/views/Dynamic.vue')
            },
            {
                path: 'dynamic/info/:id?/:menuKey?',
                name: 'DynamicInfo',
                component: () => import(/* webpackChunkName: "user" */ '@/views/DynamicInfo.vue')
            },
        ]
    },
]

Vue.use(Router)

export default new Router({
  mode: 'hash',
//   mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: basicRoute
})