<template>
    <div  class="main-header">
        <a href="/" class="logo-home">
            <div class="app-logo">
                <img src="../../assets/images/logo.png" alt="logo">
            </div>
        </a>
        <div class="app-menu">
            <el-menu
                :default-active="activiti"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
                text-color="#333 "
                active-text-color="#0960BD">
                <el-menu-item index="productFun">
                    首页
                </el-menu-item>
                <el-menu-item index="version">
                    版本
                    
                </el-menu-item>
                <el-menu-item index="dynamic">
                    动态
                </el-menu-item>
                <el-menu-item index="doc">
                    <a href="https://www.yuque.com/huxin-ch41t/kaifangqian/whgtwc8i0kxm0iic" target="_blank">文档</a>
                </el-menu-item>
                <el-menu-item index="verification">
                    验签
                </el-menu-item>
            </el-menu>
        </div>
        <div class="header-right">
            <div class="link-img">
                    <a href="https://gitee.com/kaifangqian"  target="_blank"> <svg t="1702436313918" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2450" width="26" height="26"><path d="M512 1024C229.222 1024 0 794.778 0 512S229.222 0 512 0s512 229.222 512 512-229.222 512-512 512z m259.149-568.883h-290.74a25.293 25.293 0 0 0-25.292 25.293l-0.026 63.206c0 13.952 11.315 25.293 25.267 25.293h177.024c13.978 0 25.293 11.315 25.293 25.267v12.646a75.853 75.853 0 0 1-75.853 75.853h-240.23a25.293 25.293 0 0 1-25.267-25.293V417.203a75.853 75.853 0 0 1 75.827-75.853h353.946a25.293 25.293 0 0 0 25.267-25.292l0.077-63.207a25.293 25.293 0 0 0-25.268-25.293H417.152a189.62 189.62 0 0 0-189.62 189.645V771.15c0 13.977 11.316 25.293 25.294 25.293h372.94a170.65 170.65 0 0 0 170.65-170.65V480.384a25.293 25.293 0 0 0-25.293-25.267z" fill="#C71D23" p-id="2451"></path></svg>
                    </a>
                    <a href="https://github.com/kaifangqian"  target="_blank">
                        <svg t="1702436394530" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3528" width="28" height="28"><path d="M512.465 98.744c-236.05-2.401-429.41 187.034-431.998 423.242 0.894 183.912 120.176 346.283 295.332 402.018 21.6 3.93 29.451-9.04 29.451-20.436v-71.916c-120.17 25.545-145.7-56.59-145.7-56.59a112.364 112.364 0 0 0-47.914-62.089c-39.272-25.937 3.141-25.545 3.141-25.545a90.702 90.702 0 0 1 65.978 43.624c25.358 43.993 81.26 59.548 125.678 34.976a91.569 91.569 0 0 1 27.487-56.59c-95.825-10.615-196.36-46.768-196.36-209.067a163.13 163.13 0 0 1 43.591-114.358 148.652 148.652 0 0 1 4.32-111.609c15.896-35.223 36.525-11.397 117.815 43.229a419.24 419.24 0 0 1 216.002 0c82.471-54.626 117.82-43.229 117.82-43.229a148.657 148.657 0 0 1 4.32 111.61 163.145 163.145 0 0 1 45.945 113.57c0 162.695-101.321 198.458-196.36 209.067a98.273 98.273 0 0 1 29.452 78.594v115.933c0 13.752 7.856 24.756 29.457 20.43 174.63-56.071 293.466-218.106 294.545-401.622C941.878 285.778 748.52 96.343 512.465 98.744" fill="#231F20" p-id="3529"></path></svg>
                    </a>
                </div>
                <a href="https://demo.kaifangqian.com" target="_blank">
                    <el-button type="primary" style="margin-right:10px">开源工具版试用 </el-button> 
                </a>
                <a href="https://home.kaifangqian.com" target="_blank">
                    <el-button type="primary">企业版试用</el-button>
                </a>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                activiti:'',


            }
        },
        mounted() {
            this.init();
        },
        methods: {

            init(){

            },
            handleSelect(val){
                if(val=='doc') return;
                this.$router.push('/'+ val)
            },
            edit(){

            },
        },
        computed: {

        },
        watch: {

        }
    }
</script>

<style lang="less">
.el-header{
    // height: 100px!important;
    background: #e6ebf6;
    opacity: 1;
    border-bottom:1px solid #f4f4f4;
}
.main-header{
    display: flex;
    justify-content: space-between;
    height: 66px;
    width:1200px;
    margin:0 auto;
    background: rgb(230 235 246);
    border-bottom:1px solid #f4f4f4;
    // .el-menu--horizontal>.el-menu-item{
    //     // height: 70px;
    //     // line-height: 70px;
    // }
    .logo-home{
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    .el-menu.el-menu--horizontal{
        border:none;
        background-color: rgba(250,250,250,0);
    }
  
    .app-logo{
        display: flex;
        align-items: center;
        img{
            width: 140px;
            // height: 48px;
        }
        .logo-title{
            font-size: 16px;
            color: #333333;
            font-weight: bold;
            margin-left:10px;
        }
    }
    .app-menu{
        width: 80%;
        display: flex;
        justify-content: center;
        .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
            background-color: rgba(250,250,250,0);
            color: #0960bd!important;
        }
        .el-menu--horizontal>.el-menu-item.is-active{
            color: #0960BD;
            border-bottom: none;
        }
        .el-menu-item{
            font-size: 16px;
            font-weight: 500;
            padding:0 40px;
            font-weight:600;
            &:hover{
                color: #0960BD;
            }
            a{
                text-decoration: none;
            }
        }
        .el-menu--horizontal>.el-menu-item{
            height: 100%;
            line-height: 60px;
        }
         .el-menu--horizontal>.el-menu-item:last-child{
            padding-right:0;
         }
        .el-button {
            width: 112px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
        }
        .el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered{
            margin-left:23px;
        }
    }
}
    .el-header{
        margin:0;
        padding: 0;
    }

    .header-right{
        display:flex;
        align-items:center;
        .link-img{
            display:flex;
            align-items:center;
            margin-right:20px;
            svg{
                margin:0 4px;
                cursor:pointer;
            }
        }
    }
 
</style>